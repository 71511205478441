import React, { useEffect, useState } from 'react';
import { Card, Button, Alert, Typography, Container, Stepper, Step, StepLabel, Box, Divider } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import AgreementLab from '../reqLab/AgreementLab';
import SelectAd from '../reqLab/SelectAd';
import SelectRepoPkg from '../reqLab/SelectRepoPkg';
import ReqLabConfirm from '../reqLab/ReqLabConfirm';
import SelectAllocationMode from '../reqExamLab/SelectAllocationMode';
import Summary from '../reqLab/Summary';
import SelectExpDate from '../reqLab/SelectExpDate';
import NavigationButtons from '../reqLab/NavigationButtons';
import StudentList from './StudentList';


const crypto = require("crypto-browserify");


function PubLabDettPage(props) {
    const initialExpirationDate = new Date(new Date().setMonth(new Date().getMonth() + 18));

    const [repoPkgBundleSel, setRepoPkgBundleSel] = useState([]);
    const [repoPkgSel, setRepoPkgSel] = useState([]);
    const [installedPackages] = useState([]);
    const [isPkgListModified, setIsPkgListModified] = useState(false);
    const [statusRequest, setStatusRequest] = useState();
    const [isSubmitted, setIsSubmitted] = useState(0);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isLoading, setIsLoading] = useState(0);
    const [activeStep, setActiveStep] = useState(0);
    const [physicalLabs, setPhysicalLabs] = useState([]);
    const [stepsData, setStepsData] = useState({});
    const [expirationMonthDuration, setExpirationMonthDuration] = useState('18');
    const [expirationDate, setExpirationDate] = useState(initialExpirationDate);
    const [isAgreementCompleted, setAgreementCompleted] = useState(false);
    const [adSel, setAdSel] = useState({});
    const [coperEasy, setCoperEasy] = useState([]);
    const [coperTeacher, setCoperTeacher] = useState([]);
    const [students, setStudents] = useState([]);
    const [teachers, setTeachers] = useState([]);
    const [url, setUrl] = useState('');
    const [isForceLabRipub, setForceLabRipub] = useState(false);


    // decido se fare una ripubblicazione completa del laboratorio
    // oppure solo aggiornamento lista utenti (refreshingLab)
    useEffect(() => {
        if (isPkgListModified || isForceLabRipub) {
            setUrl(process.env.REACT_APP_URL_UPD_REQ_LAB);
        } else {
            setUrl(process.env.REACT_APP_URL_UPD_REG_STD);
        }
    }, [isPkgListModified, isForceLabRipub]);


    const stepsExam = [
        'Pacchetti aggiuntivi',
        'Durata laboratorio',
        'Attività didattica',
        'Allocazione postazioni esame',
        'Agreement',
        'Conferma'
    ];

    const stepsStandard = [
        'Pacchetti aggiuntivi',
        'Durata laboratorio',
        'Attività didattica',
        'Modifica Docenti e Studenti',
        'Agreement',
        'Conferma'
    ];

    const {
        labProps,
        isAdmin,
    } = props;

    const formatDate = (isoDate) => {
        const date = new Date(isoDate);
        const options = { day: '2-digit', month: 'short', year: 'numeric' };
        return date.toLocaleDateString('it-IT', options);
    };


    const handleAgreementCompletion = (isAccepted) => {
        setAgreementCompleted(isAccepted);
    };

    const handleExpDateChange = (newExpDate) => {
        const currentDate = new Date();
        const monthDurations = {
            '3': 3,
            '6': 6,
            '12': 12,
            '18': 18
        };

        // Calcola la nuova data di scadenza
        const monthsToAdd = monthDurations[newExpDate] || 0;
        const calculatedExpirationDate = new Date(currentDate.setMonth(currentDate.getMonth() + monthsToAdd));
        const isoExpirationDate = calculatedExpirationDate.toISOString();

        // Aggiorna gli stati
        setExpirationMonthDuration(newExpDate);
        setExpirationDate(isoExpirationDate);

        console.log("DEBUG expirationMonthDuration: " + newExpDate);
        console.log("DEBUG expirationDate: " + isoExpirationDate);
    };


    const handleNext = () => {
        // get the data of the current step
        let currentStepData;
        switch (activeStep) {
            // selezione pacchetti
            case 0:
                let repoPkgSelString;
                if (repoPkgSel.length === 0) {
                    repoPkgSelString = "Nessun pacchetto addizionale";
                } else {
                    repoPkgSelString = repoPkgSel.map(item => item.value).join(', ');
                }

                currentStepData = (
                    <Typography>
                        Pacchetti aggiuntivi selezionati: <Typography component="span" fontWeight="bold">{repoPkgSelString}</Typography>
                    </Typography>
                );
                break;
            // selezione data di scadenza
            case 1:
                currentStepData = (
                    <Typography>
                        Data di scadenza: <Typography component="span" fontWeight="bold">{expirationMonthDuration} mesi (data di scadenza: {formatDate(expirationDate)})</Typography>
                    </Typography>
                );
                break;
            // attività didattica
            case 2:
                if (Object.keys(adSel).length === 0) {
                    currentStepData = (
                        <Typography>
                            Attività didattica: <Typography component="span" fontWeight="bold">Nessuna attività didattica selezionata</Typography>
                        </Typography>
                    );
                } else {
                    currentStepData = (
                        <Typography>
                            Attività didattica: <Typography component="span" fontWeight="bold">[{adSel.anaAfCod}-{adSel.anaModCod}] {adSel.anaAfDescIta}</Typography>
                        </Typography>
                    );
                }
                break;
            // selezione lista studenti da escludere (standard) o allocazione postazioni esame (exam)
            case 3:
                currentStepData = (
                    labProps.labType === "exam" ? (
                        <Typography>
                            Allocazione postazioni esame: <Typography component="span" fontWeight="bold">{physicalLabs.join(', ')}</Typography>
                        </Typography>
                    ) : (
                        <>
                            <Typography>
                                Elenco studenti iscritti aggiornato: <Typography component="span" fontWeight="bold">{students.map(student => student.email).join(', ')}</Typography>
                            </Typography>
                            <Typography>
                                Elenco docenti aggiornato: <Typography component="span" fontWeight="bold">{teachers.map(teacher => teacher.email).join(', ')}</Typography>
                            </Typography>
                        </>
                    )
                );
                break;
            // agreement
            case 4:
                currentStepData = (
                    <Typography>
                        Nota sulla mancata persistenza accettata
                    </Typography>
                );
                break;
            default:
                break;
        }


        // update stepsData with the data of the current step
        setStepsData(prevStepsData => ({
            ...prevStepsData,
            [`Step ${activeStep + 1}`]: currentStepData,
        }));

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        switch (activeStep) {
            // 
            case 0:
                break;
            case 1:
                break;
            case 2:
                break;
            case 3:
                setAdSel({});
                break;
            case 4:
                setAgreementCompleted(false);
                break;
            case 5:
                setAgreementCompleted(false);
                break;
            default:
                break;
        }

        // Cancella i dati dello step corrente
        setStepsData((prevStepsData) => {
            const newStepsData = { ...prevStepsData };
            delete newStepsData[`Step ${activeStep}`];
            return newStepsData;
        });

        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };


    const usageType = () => {
        const stepsToUse = labProps.labType === "exam" ? stepsExam : stepsStandard;

        return (
            <React.Fragment>
                <form onSubmit={onSubmit}>

                    <Container>
                        <Summary stepsData={stepsData} />
                        <Stepper activeStep={activeStep} alternativeLabel>
                            {stepsToUse.map((label) => (
                                <Step key={label}>
                                    <StepLabel>{label}</StepLabel>
                                </Step>
                            ))}
                        </Stepper>
                        {activeStep === 0 && (
                            <Box style={{ width: "100%", marginTop: "50px" }}>
                                <NavigationButtons ldisabled={true} rdisabled={false} handleBack={handleBack}
                                    handleNext={handleNext} />
                                <SelectRepoPkg
                                    apiAuthResponse={props.apiAuthResponse}
                                    apiAuthName={props.apiAuthName}
                                    apiAuthSurname={props.apiAuthSurname}
                                    apiAuthGroups={props.apiAuthGroups}
                                    setRepoPkgSel={setRepoPkgSel}
                                    setRepoPkgBundleSel={setRepoPkgBundleSel}
                                    installedPackages={labProps.repoPkgSel}
                                    baseImage={labProps.baseImage}
                                    setIsPkgListModified={setIsPkgListModified}
                                />
                                <NavigationButtons ldisabled={false} rdisabled={false} handleBack={handleBack}
                                    handleNext={handleNext} />
                            </Box>
                        )}
                        {activeStep === 1 && (
                            <Box style={{ width: "100%", marginTop: "50px" }}>
                                <NavigationButtons ldisabled={false} rdisabled={false} handleBack={handleBack}
                                    handleNext={handleNext} />
                                <SelectExpDate
                                    expirationDate={labProps.expirationDate}
                                    expirationMonthDuration={expirationMonthDuration}
                                    onExpDateChange={handleExpDateChange} />
                            </Box>
                        )}
                        {activeStep === 2 && (
                            <Box style={{ width: "100%", marginTop: "50px" }}>
                                <NavigationButtons ldisabled={false} rdisabled={false} handleBack={handleBack}
                                    handleNext={handleNext} />
                                <SelectAd
                                    apiAuthResponse={props.apiAuthResponse}
                                    apiAuthName={props.apiAuthName}
                                    apiAuthSurname={props.apiAuthSurname}
                                    apiAuthGroups={props.apiAuthGroups}
                                    setAdSelected={setAdSel}
                                    adSelected={labProps.adSel}
                                    setCoperEasy={setCoperEasy}
                                    setCoperTeacher={setCoperTeacher}
                                    labName={labProps.labName}
                                //onCompletion={handleSelectAdCompletion}
                                />
                                <NavigationButtons ldisabled={false} rdisabled={false} handleBack={handleBack}
                                    handleNext={handleNext} />
                            </Box>
                        )}
                        {activeStep === 3 && (
                            <Box style={{ width: "100%", marginTop: "50px" }}>
                                <NavigationButtons ldisabled={false} rdisabled={false} handleBack={handleBack} handleNext={handleNext} />
                                {labProps.labType === "exam" ? (
                                    <SelectAllocationMode
                                        apiAuthResponse={props.apiAuthResponse}
                                        setStudents={setStudents}
                                        students={students}
                                        setPhysicalLabs={setPhysicalLabs}
                                        physicalLabs={labProps.physicalLabs}
                                    />
                                ) : (
                                    <StudentList
                                        status={labProps.status}
                                        isAdmin={isAdmin}
                                        apiAuthResponse={props.apiAuthResponse}
                                        isLoading={isLoading}
                                        students={labProps.students}
                                        teachers={labProps.teachers}
                                        setStudents={setStudents}
                                        setTeachers={setTeachers}
                                    />
                                )}
                                <NavigationButtons ldisabled={false} rdisabled={false} handleBack={handleBack} handleNext={handleNext} />
                            </Box>
                        )}
                        {activeStep === 4 && (
                            <Box style={{ width: "100%", marginTop: "50px" }}>
                                <NavigationButtons ldisabled={false} rdisabled={!isAgreementCompleted} handleBack={handleBack}
                                    handleNext={handleNext} />
                                <AgreementLab
                                    onCompletion={handleAgreementCompletion}
                                />
                            </Box>
                        )}
                        {activeStep === 5 && (
                            <Box style={{ width: "100%", marginTop: "50px" }}>
                                <NavigationButtons ldisabled={isSubmitted} rdisabled={true} handleBack={handleBack}
                                    handleNext={handleNext} />
                                <Typography variant="body1" sx={{ marginRight: 1 }}>
                                    Ripubblica il laboratorio ricominciando dal template:
                                    <Checkbox
                                        checked={isForceLabRipub}
                                        onChange={(e) => setForceLabRipub(e.target.checked)}
                                    />
                                </Typography>

                                <ReqLabConfirm
                                    isSubmitted={isSubmitted}
                                    isSubmitble={true}
                                    isSubmitting={isSubmitting}
                                    statusRequest={statusRequest}
                                    apiAuthGroups={props.apiAuthGroups}
                                    buttonDescription={"Modifica laboratorio"}
                                    isAdmin={isAdmin}
                                />

                            </Box>
                        )}
                    </Container>
                </form>
            </React.Fragment>
        );


    }



    // la logica se ripubblicare il laboratorio o mettere in refreshinglab è regolata dalla hook url
    const onSubmit = async (e) => {
        e.preventDefault();
        let formBody = [];
        let tempArraySelectedPackages = [];
        let myTeachers = [];
        let labEvents = [];

        if (isSubmitting) return; // Evita doppio invio

        setIsSubmitting(true); // Imposta lo stato di invio
        setIsLoading(1);

        try {
            tempArraySelectedPackages = installedPackages.concat(repoPkgSel);

            const currentTeacher = [{ email: props.apiAuthResponse, partitions: [0] }];
            const allTeachers = [...coperTeacher, ...teachers, ...currentTeacher];
            myTeachers = Array.from(new Set(allTeachers.map(teacher => teacher.email)))
                .map(email => allTeachers.find(teacher => teacher.email === email));

            let myRegisterUrls = [];

            // Aggiorna registerUrls con le entry mancanti da myTeachers
            myTeachers.forEach(teacher => {
                if (!labProps.registerUrls.some(url => url.teacher.email === teacher.email)) {
                    myRegisterUrls.push({
                        teacher: {
                            email: teacher.email,
                            partition: 0,
                        },
                        registerUrl: crypto.randomBytes(10).toString('hex')
                    });
                }
            });

            myRegisterUrls = [...labProps.registerUrls, ...myRegisterUrls];

            if (coperEasy.length > 0) {
                labEvents = coperEasy.map(event => ({
                    ...event,
                    scheduleType: "easy",
                    teacher: { email: props.apiAuthResponse, partition: 0 }
                }));
            } else {
                labEvents = [];
            }

            const payload = (
                {
                    idLab: labProps.idLab,
                    email: props.apiAuthResponse,
                    teachers: myTeachers,
                    labName: labProps.labName,
                    expirationDate: expirationDate,
                    labDescription: labProps.labDescription,
                    repoPkgBundleSel: repoPkgBundleSel,
                    repoPkgSel: tempArraySelectedPackages,
                    adSel: adSel,
                    labOtherActivities: labProps.labOtherActivities,
                    labNotes: labProps.labNotes,
                    baseImage: {
                        os: labProps.baseImage.os,
                        name: labProps.baseImage.name,
                        des: labProps.baseImage.des,
                        size: labProps.baseImage.size,
                        feed: labProps.baseImage.feed,
                        feedBundle: labProps.baseImage.feedBundle,
                        imageType: labProps.baseImage.imageType,
                        destination: labProps.baseImage.destination,
                    },
                    usageData: {
                        maxStudentsPerPartition: labProps.usageData.maxStudentsPerPartition,
                        //quota: labProps.usageDataquota,
                        schedulerLab: labEvents,
                    },
                    students: students,
                    physicalLabs: physicalLabs,
                    registerUrls: myRegisterUrls,
                }
            );

            formBody.push("payload=" + encodeURIComponent(JSON.stringify(payload)));

            const response = await fetch(url, {
                credentials: 'include',
                method: "POST",
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
                },
                body: formBody
            });

            const data = await response.json();
            setIsSubmitted(1);
            setIsLoading(0);
            setStatusRequest(data.statusRequest);

        } catch (error) {
            console.log(error, "Errore!")
        } finally {
            setTimeout(() => setIsSubmitting(false), 500);
        }

    }


    let ret = (

        <React.Fragment>
            <Typography variant="h4" className="title-page">
                Modifica Laboratorio Virtuale
            </Typography>
            <Box sx={{ padding: 3 }}>
                <Typography variant="body1" gutterBottom>
                    Da questa pagina è possibile modificare le proprietà di un Laboratorio Virtuale
                </Typography>
                <Typography variant="body1" gutterBottom>
                    <strong>idLab</strong> {labProps.idLab}
                </Typography>
                <Typography variant="body1" gutterBottom>
                    <strong>Nome laboratorio</strong> {labProps.labName}
                </Typography>
                <Typography variant="body1" gutterBottom>
                    <strong>Descrizione</strong> {labProps.labDescription}
                </Typography>
                <Typography variant="body1" gutterBottom>
                    <strong>Immagine base</strong> {labProps.baseImage.name}
                </Typography>
                <Typography variant="body1" gutterBottom>
                    <strong>Dimensionamento</strong> {labProps.baseImage.size}
                </Typography>


                <Alert severity="warning" sx={{ marginBottom: 2 }}>
                    <strong>Attenzione:</strong> la modifica delle proprietà di un laboratorio virtuale comporta la cancellazione di tutte le macchine virtuali associate.
                    Se fatto durante una lezione causa l'interruzione della lezione.
                </Alert>
            </Box>

            {usageType()}

            <Divider sx={{ marginY: 2 }} />
            <Card sx={{ padding: 3, display: 'flex', justifyContent: 'left', alignItems: 'center' }}>
                <Button
                    variant="contained"
                    type="submit"
                    size="sm"
                    onClick={() => { props.setIsShowSelectedLab(false) }}
                >
                    Torna alla vista complessiva dei laboratori
                </Button>
            </Card>
        </React.Fragment>
    );


    if (labProps) {
        return ret;
    } else {
        return (
            <Button
                size="sm"
                variant="contained"
                type="submit"
                onClick={() => { props.setIsShowSelectedLab(false) }}
            >
                Torna alla vista complessiva dei laboratori
            </Button>
        );
    }

}

export default PubLabDettPage;
