import React, { useRef } from 'react';
import { TextField, InputLabel, Typography, Alert, Card } from '@mui/material';

function LabDescription(props) {
    const targetBadDescriptionAlert = useRef(null);
    const {
        isLabdescriptionSpellChecked,
        setIsLabdescriptionSpellChecked,
        setLabDescription,
    } = props;


    const labDescriptionHandler = (event) => {
        let labDesc = event.target.value;
        //const sanitizedValue = labDesc.replace(/[^a-zA-Z0-9-_ ]/g, ""); // Permette solo lettere, numeri, "-"
        const sanitizedValue = labDesc.substring(0, 2000); // Limita la lunghezza a 4000 caratteri

        if (labDesc !== sanitizedValue) {
            setIsLabdescriptionSpellChecked(false); // Segnala errore se ci sono caratteri vietati
        } else {
            setIsLabdescriptionSpellChecked(true);
            setLabDescription(sanitizedValue);
        }

    }

    return (
        <Card sx={{ padding: 3 }}>
            <InputLabel>Descrizione del Laboratorio Virtuale</InputLabel>
            <Typography variant="body2" color="textSecondary">
                <b>Dato obbligatorio</b>
            </Typography>
            <TextField
                fullWidth
                type="text"
                onChange={labDescriptionHandler}
                placeholder="Descrizione del Laboratorio"
                variant="outlined"
                margin="normal"
                inputRef={targetBadDescriptionAlert}
                required
                error={!isLabdescriptionSpellChecked}
            />

            {!isLabdescriptionSpellChecked &&
                <Alert variant="danger">
                    Attenzione! Il nome inserito contiene caratteri non consentiti. Inserire un nome diverso.
                </Alert>
            }
        </Card>
    );
}

export default LabDescription;