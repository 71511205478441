import React, { useState } from 'react';
import { Typography, Container, Stepper, Step, StepLabel, TextField, Box, Card } from '@mui/material';
import SelectAd from './SelectAd';
import SelectRepoPkg from './SelectRepoPkg';
import ReqLabConfirm from './ReqLabConfirm';
import MultipleStepBaseImage from './MultipleStepBaseImage';
import SelectSize from './SelectSize';
import LabName from './LabName';
import LabDescription from './LabDescription';
import AgreementLab from './AgreementLab';
import SelectExpDate from './SelectExpDate';
import Summary from './Summary';
import NavigationButtons from './NavigationButtons';


function ReqLabPage(props) {
    const initialExpirationDate = new Date(new Date().setMonth(new Date().getMonth() + 18));

    const [activeStep, setActiveStep] = useState(0);

    const [adSel, setAdSel] = useState({});
    const [coperEasy, setCoperEasy] = useState([]);
    const [coperTeacher, setCoperTeacher] = useState([]);
    const [repoPkgBundleSel, setRepoPkgBundleSel] = useState([]);
    const [repoPkgSel, setRepoPkgSel] = useState([]);
    const [repoPkgBundleSelString, setRepoPkgBundleSelString] = useState("");
    const [installedPackages] = useState([]);
    const [labName, setLabName] = useState("");
    const [labDescription, setLabDescription] = useState("");
    const [labNotes, setLabNotes] = useState();
    const [baseImage, setBaseImage] = useState({});
    const [isBookable, setIsBookable] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(0);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [statusRequest, setStatusRequest] = useState();
    const [isLabnameChecked, setIsLabnameChecked] = useState(true);
    const [isLabdescriptionSpellChecked, setIsLabdescriptionSpellChecked] = useState(true);
    const [expirationMonthDuration, setExpirationMonthDuration] = useState('18');
    const [expirationDate, setExpirationDate] = useState(initialExpirationDate);
    const [isMultipleBaseStepCompleted, setMultipleBaseStepCompleted] = useState(false);
    const [isLabNameCompleted, setLabNameCompleted] = useState(false);
    const [isAgreementCompleted, setAgreementCompleted] = useState(false);
    const [stepsData, setStepsData] = useState({});


    const labType = "standard";

    const steps = [
        'Immagine base',
        'Pacchetti aggiuntivi',
        'Durata laboratorio',
        'Dimensionamento macchine',
        'Attività didattica',
        'Nome Laboratorio',
        'Descrizonione Laboratorio',
        'Note aggiuntive',
        'Agreement',
        'Conferma'
    ];


    const formatDate = (isoDate) => {
        const date = new Date(isoDate);
        const options = { day: '2-digit', month: 'short', year: 'numeric' };
        return date.toLocaleDateString('it-IT', options);
    };


    // Funzione per gestire il passaggio alla prossima fase
    const handleNext = () => {
        // get the data of the current step
        let currentStepData;
        switch (activeStep) {
            // selezione immagine base
            case 0:
                currentStepData = (
                    <Typography>
                        Immagine Selezionata: <Typography component="span" fontWeight="bold">{baseImage.imageName} {baseImage.imageDes}</Typography>
                        <br />
                        Pacchetti presenti nel bundle: <Typography component="span" fontWeight="bold">{repoPkgBundleSelString}</Typography>
                    </Typography>
                );
                break;
            // selezione pacchetti
            case 1:
                let repoPkgSelString;
                if (repoPkgSel.length === 0) {
                    repoPkgSelString = "Nessun pacchetto addizionale";
                } else {
                    repoPkgSelString = repoPkgSel.map(item => item.value).join(', ');
                }

                currentStepData = (
                    <Typography>
                        Pacchetti aggiuntivi selezionati: <Typography component="span" fontWeight="bold">{repoPkgSelString}</Typography>
                    </Typography>
                );
                break;
            // selezione data di scadenza
            case 2:
                currentStepData = (
                    <Typography>
                        Data di scadenza: <Typography component="span" fontWeight="bold">{expirationMonthDuration} mesi (data di scadenza: {formatDate(expirationDate)})</Typography>
                    </Typography>
                );
                break;
            // selezione dimensionamento
            case 3:
                currentStepData = (
                    <Typography>
                        Selezione dimensionamento: <Typography component="span" fontWeight="bold">{baseImage.imageSize}</Typography>
                    </Typography>
                );
                break;
            case 4:
                if (Object.keys(adSel).length === 0) {
                    currentStepData = (
                        <Typography>
                            Attività didattica: <Typography component="span" fontWeight="bold">Nessuna attività didattica selezionata</Typography>
                        </Typography>
                    );
                } else {
                    currentStepData = (
                        <Typography>
                            Attività didattica: <Typography component="span" fontWeight="bold">[{adSel.anaAfCod}-{adSel.anaModCod}] {adSel.anaAfDescIta}</Typography>
                        </Typography>
                    );
                }
                break;
            case 5:
                currentStepData = (
                    <Typography>
                        Nome laboratorio: <Typography component="span" fontWeight="bold">{labName}</Typography>
                    </Typography>
                );
                break;
            case 6:
                currentStepData = (
                    <Typography>
                        Descrizione laboratorio: <Typography component="span" fontWeight="bold">{labDescription}</Typography>
                    </Typography>
                );
                break;
            case 7:
                currentStepData = (
                    <Typography>
                        Note aggiuntive: <Typography component="span" fontWeight="bold">{labNotes}</Typography>
                    </Typography>
                );
                break;
            case 8:
                currentStepData = (
                    <Typography>
                        Nota sulla mancata persistenza accettata
                    </Typography>
                );
                break;
            default:
                break;
        }

        // update stepsData with the data of the current step
        setStepsData(prevStepsData => ({
            ...prevStepsData,
            [`Step ${activeStep + 1}`]: currentStepData,
        }));


        // increment activeStep
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    // Funzione per gestire il ritorno alla fase precedente
    const handleBack = () => {
        switch (activeStep) {
            // 
            case 0:
                break;
            // selezione immagine base
            case 1:
                setMultipleBaseStepCompleted(false);
                break;
            // selezione pacchetti
            case 2:
                break;
            // selezione data di scadenza
            case 3:
                break;
            // selezione dimensionamento
            case 4:
                break;
            // selezione AD
            case 5:
                setAdSel({});
                break;
            // selezione nome laboratorio
            case 6:
                setLabNameCompleted(false);
                break;
            // descrizione laboratorio
            case 7:
                break;
            // note aggiuntive
            case 8:
                break;
            case 9:
                setAgreementCompleted(false);
                break;
            case 10:
                setAgreementCompleted(false);
                break;
            default:
                break;
        }

        // Cancella i dati dello step corrente
        setStepsData((prevStepsData) => {
            const newStepsData = { ...prevStepsData };
            delete newStepsData[`Step ${activeStep}`];
            return newStepsData;
        });

        // Logica esistente per gestire il passo indietro
        setActiveStep((prevActiveStep) => prevActiveStep - 1);

    };


    const handleLabNameCompletion = (nameOk) => {
        setLabNameCompleted(nameOk);
    };


    const labNotesHandler = (event) => {
        setLabNotes(event.target.value);
    }

    const handleExpDateChange = (newExpDate) => {
        const currentDate = new Date();
        const monthDurations = {
            '3': 3,
            '6': 6,
            '12': 12,
            '18': 18
        };

        // Calcola la nuova data di scadenza
        const monthsToAdd = monthDurations[newExpDate] || 0;
        const calculatedExpirationDate = new Date(currentDate.setMonth(currentDate.getMonth() + monthsToAdd));
        const isoExpirationDate = calculatedExpirationDate.toISOString();

        // Aggiorna gli stati
        setExpirationMonthDuration(newExpDate);
        setExpirationDate(isoExpirationDate);
    };


    const handleAgreementCompletion = (isAccepted) => {
        setAgreementCompleted(isAccepted);
    };

    const usageType = () => {
        return (
            <React.Fragment>
                <form onSubmit={onSubmit}>

                    <Container>
                        <Summary stepsData={stepsData} />
                        <Stepper activeStep={activeStep} alternativeLabel>
                            {steps.map((label) => (
                                <Step key={label}>
                                    <StepLabel>{label}</StepLabel>
                                </Step>
                            ))}
                        </Stepper>
                        {activeStep === 0 && (
                            <Box style={{ width: "100%", marginTop: "50px" }}>
                                <NavigationButtons ldisabled={true} rdisabled={!isMultipleBaseStepCompleted} handleBack={handleBack}
                                    handleNext={handleNext} />
                                <MultipleStepBaseImage
                                    setBaseImage={setBaseImage}
                                    baseImage={baseImage}
                                    setIsBookable={setIsBookable}
                                    isBookable={isBookable}
                                    setRepoPkgBundleSelString={setRepoPkgBundleSelString}
                                    setMultipleBaseStepCompleted={setMultipleBaseStepCompleted}
                                />
                                <NavigationButtons ldisabled={true} rdisabled={!isMultipleBaseStepCompleted} handleBack={handleBack}
                                    handleNext={handleNext} />
                            </Box>
                        )}
                        {activeStep === 1 && (
                            <Box style={{ width: "100%", marginTop: "50px" }}>
                                <NavigationButtons ldisabled={false} rdisabled={false} handleBack={handleBack}
                                    handleNext={handleNext} />
                                <SelectRepoPkg
                                    apiAuthResponse={props.apiAuthResponse}
                                    apiAuthName={props.apiAuthName}
                                    apiAuthSurname={props.apiAuthSurname}
                                    apiAuthGroups={props.apiAuthGroups}
                                    setRepoPkgSel={setRepoPkgSel}
                                    setRepoPkgBundleSel={setRepoPkgBundleSel}
                                    installedPackages={installedPackages}
                                    baseImage={baseImage}
                                />
                                <NavigationButtons ldisabled={false} rdisabled={false} handleBack={handleBack}
                                    handleNext={handleNext} />
                            </Box>
                        )}
                        {activeStep === 2 && (
                            <Box style={{ width: "100%", marginTop: "50px" }}>
                                <NavigationButtons ldisabled={false} rdisabled={false} handleBack={handleBack}
                                    handleNext={handleNext} />
                                <SelectExpDate
                                    expirationMonthDuration={expirationMonthDuration}
                                    onExpDateChange={handleExpDateChange} />
                            </Box>
                        )}
                        {activeStep === 3 && (
                            <Box style={{ width: "100%", marginTop: "50px" }}>
                                <NavigationButtons ldisabled={false} rdisabled={false} handleBack={handleBack}
                                    handleNext={handleNext} />
                                <SelectSize
                                    setBaseImage={setBaseImage}
                                    baseImage={baseImage}
                                />
                            </Box>

                        )}
                        {activeStep === 4 && (
                            <Box style={{ width: "100%", marginTop: "50px" }}>
                                <NavigationButtons ldisabled={false} rdisabled={false} handleBack={handleBack}
                                    handleNext={handleNext} />
                                <SelectAd
                                    apiAuthResponse={props.apiAuthResponse}
                                    apiAuthName={props.apiAuthName}
                                    apiAuthSurname={props.apiAuthSurname}
                                    apiAuthGroups={props.apiAuthGroups}
                                    setAdSelected={setAdSel}
                                    setCoperEasy={setCoperEasy}
                                    setCoperTeacher={setCoperTeacher}
                                    labName={labName}
                                    adSelected={adSel}
                                />
                                <NavigationButtons ldisabled={false} rdisabled={false} handleBack={handleBack}
                                    handleNext={handleNext} />
                            </Box>
                        )}
                        {activeStep === 5 && (
                            <Box style={{ width: "100%", marginTop: "50px" }}>
                                <NavigationButtons ldisabled={false} rdisabled={!isLabNameCompleted || !isLabnameChecked} handleBack={handleBack}
                                    handleNext={handleNext} />
                                <LabName
                                    apiAuthResponse={props.apiAuthResponse}
                                    apiAuthName={props.apiAuthName}
                                    apiAuthSurname={props.apiAuthSurname}
                                    apiAuthGroups={props.apiAuthGroups}
                                    adSel={adSel}
                                    labNameSelected={setLabName}
                                    setIsLabnameChecked={setIsLabnameChecked}
                                    isLabnameChecked={isLabnameChecked}
                                    onCompletion={handleLabNameCompletion}
                                />
                            </Box>
                        )}
                        {activeStep === 6 && (
                            <Box style={{ width: "100%", marginTop: "50px" }}>
                                <NavigationButtons ldisabled={false} rdisabled={!isLabdescriptionSpellChecked || labDescription.length === 0} handleBack={handleBack}
                                    handleNext={handleNext} />
                                <LabDescription
                                    isLabdescriptionSpellChecked={isLabdescriptionSpellChecked}
                                    setIsLabdescriptionSpellChecked={setIsLabdescriptionSpellChecked}
                                    setLabDescription={setLabDescription}
                                />
                            </Box>
                        )}

                        {activeStep === 7 && (
                            <Box style={{ width: "100%", marginTop: "50px" }}>
                                <label style={{ width: "85%", marginTop: "50px" }}> Note aggiuntive </label>
                                <NavigationButtons ldisabled={false} rdisabled={false} handleBack={handleBack}
                                    handleNext={handleNext} />
                                <Card sx={{ padding: 3 }}>
                                    <TextField
                                        multiline
                                        rows={3}
                                        maxLength={2000}
                                        placeholder="Inserire eventuali note aggiuntive"
                                        onChange={labNotesHandler}
                                        fullWidth
                                    />
                                </Card>
                            </Box>
                        )}
                        {activeStep === 8 && (
                            <Box style={{ width: "100%", marginTop: "50px" }}>
                                <NavigationButtons ldisabled={false} rdisabled={!isAgreementCompleted} handleBack={handleBack}
                                    handleNext={handleNext} />
                                <AgreementLab
                                    onCompletion={handleAgreementCompletion}
                                />
                            </Box>
                        )}
                        {activeStep === 9 && (
                            <Box style={{ width: "100%", marginTop: "50px" }}>
                                <NavigationButtons ldisabled={false} rdisabled={true} handleBack={handleBack}
                                    handleNext={handleNext} />
                                <ReqLabConfirm
                                    isSubmitted={isSubmitted}
                                    isSubmitble={true}
                                    isSubmitting={isSubmitting}
                                    statusRequest={statusRequest}
                                    apiAuthGroups={props.apiAuthGroups}
                                    buttonDescription={"Istanzia laboratorio"}
                                />
                            </Box>
                        )}
                    </Container>
                </form>
            </React.Fragment>
        );


    }


    const onSubmit = async (e) => {
        e.preventDefault();

        if (activeStep !== 9) {
            console.log("Submit non permesso in questo step");
            return;
        }

        let formBody = [];
        let myTeachers = [];
        let labEvents = [];
        let url;

        if (isSubmitting) return; // Evita doppio invio

        setIsSubmitting(true); // Imposta lo stato di invio

        try {
            url = process.env.REACT_APP_URL_SAVE_REQ_LAB;

            if (coperTeacher.length > 0) {
                myTeachers = coperTeacher;
            } else {
                myTeachers = [{ email: props.apiAuthResponse, partitions: [0] }];
            }

            if (coperEasy.length > 0) {
                labEvents = coperEasy.map(event => ({
                    ...event,
                    scheduleType: "easy",
                    teacher: { email: props.apiAuthResponse, partition: 0 }
                }));
            } else {
                labEvents = [];
            }


            const payload = (
                {
                    idLab: baseImage.idLab,
                    email: props.apiAuthResponse,
                    //teachers: [{ email: props.apiAuthResponse, partitions: [0] }],
                    //teachers: coperTeacher,
                    teachers: myTeachers,
                    labName: labName,
                    expirationDate: expirationDate,
                    labDescription: labDescription,
                    labType: labType,
                    repoPkgBundleSel: repoPkgBundleSel,
                    repoPkgSel: repoPkgSel,
                    adSel: adSel,
                    labOtherActivities: "",
                    labNotes: labNotes,
                    baseImage: {
                        os: baseImage.imageOs,
                        name: baseImage.imageName,
                        des: baseImage.imageDes,
                        size: baseImage.imageSize,
                        feed: baseImage.feed,
                        feedBundle: baseImage.feedBundle,
                        imageType: baseImage.imageType,
                        destination: baseImage.destination,
                    },
                    usageData: {
                        maxStudentsPerPartition: 0,
                        //quota: quota,
                        schedulerLab: labEvents,
                    },
                    students: []
                }
            );

            formBody.push("payload=" + encodeURIComponent(JSON.stringify(payload)));

            const response = await fetch(url, {
                credentials: 'include',
                method: "POST",
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
                },
                body: formBody
            });

            const data = await response.json();

            setIsSubmitted(1);
            setStatusRequest(data.statusRequest);
        } catch (error) {
            console.log(error, "Errore!");
        } finally {
            setTimeout(() => setIsSubmitting(false), 500);
        }
    }


    return (
        <React.Fragment>
            <h2 className="title-page">Richiesta Laboratorio Virtuale</h2>
            <p>Da questa pagina è possibile attivare un Laboratorio Virtuale</p>


            <br />

            {usageType(baseImage)}


        </React.Fragment>
    );

}

export default ReqLabPage;
