import React, { useState, useEffect, useCallback, useMemo } from 'react';
import ClipLoader from "react-spinners/ClipLoader";
import { MenuItem, FormControl, InputLabel, Select, Grid, TextField, Typography } from '@mui/material';
import {
    MaterialReactTable,
    useMaterialReactTable,
} from 'material-react-table';



const crypto = require("crypto-browserify");


function SelectLib(props) {
    const [isLoading, setIsLoading] = useState(1)
    const [dataLib, setDataLib] = useState([]);
    const [searchLib, setSearchLib] = useState("");
    const [chiave, setChiave] = useState("nome");
    const [chiaveDes, setChiaveDes] = useState("Nome LIB");
    const [isKeySelected, setIsKeySelected] = useState(true);
    const [reloadData, setReloadData] = useState(false);
    const [rowSelection, setRowSelection] = useState([]);

    const { allocationMode,
        labServicesVirtualmachines,
        setStudents,
        physicalLabs,
        setPhysicalLabs,
        apiAuthResponse,
    } = props;

    const columnsLib = useMemo(
        () => [
            {
                accessorKey: "idLabInventory",
                header: "ID",
                size: "12",
            },
            {
                accessorKey: "nome",
                header: "LAB",
                size: "120",
            },
            {
                accessorKey: "edificio",
                header: "Edificio",
                size: "150",
            },
            {
                accessorKey: "computerName",
                header: "Computer Name",
                size: "150",
            },
            {
                accessorKey: "macAddress",
                header: "MAC Address",
                size: "150",
            },
            {
                accessorKey: "ip",
                header: "IP",
                size: "150",
            },
        ],
        [],
    );


    var ret;

    const searchLibHandler = (lib) => {
        setSearchLib(lib.target.value);
    }

    const selectChiave = (key, description) => {
        setChiave(key);
        setChiaveDes(description);
        setIsKeySelected(true);
    }

    const defineUser = useCallback((computerName, macAddress, ip, userType, labServicesVirtualmachineIdx) => {
        var randomUpperChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
        var randomSpecialChars = '!#$-.';
        let strippedComputerName = computerName.replace(/lab/gi, '');
        strippedComputerName = strippedComputerName.replace(/tutor/gi, 'TUT');

        var myLabServices;

        if (typeof labServicesVirtualmachines === "object" && labServicesVirtualmachines.length > 0) {
            myLabServices = labServicesVirtualmachines[labServicesVirtualmachineIdx];
        }

        var myUser = {
            //samAccountName: todayStr + crypto.randomBytes(3).toString('hex'),
            email: (process.env.REACT_APP_EXAMUSER_PREFIX + strippedComputerName + "-" + crypto.randomBytes(6).toString('hex')).slice(0, 20),
            quotaConsumed: 0,
            userType: userType,
            password: crypto.randomBytes(7).toString('hex') +
                randomUpperChars.charAt(Math.floor(Math.random() * randomUpperChars.length)) +
                randomSpecialChars.charAt(Math.floor(Math.random() * randomSpecialChars.length)),
            teacher: {
                email: apiAuthResponse,
                partition: 0
            },
            postazione: {
                macAddress: macAddress,
                ip: ip,
                computerName: computerName
            },
            labServices: myLabServices
        }

        //console.log("debug defineUser: " + JSON.stringify(myUser))

        return myUser;
    }, [apiAuthResponse, labServicesVirtualmachines])




    useEffect(() => {
        var userType = 'exam';
        if (typeof labServicesVirtualmachines === "object" && labServicesVirtualmachines.length > 0) {
            userType = 'labServices';
        }
        setStudents([]);

        let labNamesArray = [];
        var k = 0;

        const selectedLabs = dataLib.flatMap((item, i) =>
            item.postazioni.flatMap((postazione, j) => {
                //console.log("DEBUG indice lab : " + i + " indice postazione: " + j + "indice  " + k );
                if (rowSelection[`${i}.${j}`]) {
                    // Salva il nome del lab
                    labNamesArray.push(item.nome);
                    var myStudent;
                    if (allocationMode === "choose" || allocationMode === "all") {
                        myStudent = defineUser(postazione.computerName, postazione.macAddress, postazione.ip, userType, k);
                        setStudents(prevStudents => [...prevStudents, myStudent]);
                        k++;
                        return [postazione.computerName];
                    } else if (allocationMode === "even") {
                        if (j % 2 === 0) {
                            myStudent = defineUser(postazione.computerName, postazione.macAddress, postazione.ip, userType, k);
                            setStudents(prevStudents => [...prevStudents, myStudent]);
                            k++;
                            return [postazione.computerName];
                        } else {
                            const newRowSelection = { ...rowSelection };
                            delete newRowSelection[`${i}.${j}`];
                            setRowSelection(newRowSelection);
                            return [];
                        }
                    } else if (allocationMode === "odd") {
                        if (j % 2 !== 0) {
                            myStudent = defineUser(postazione.computerName, postazione.macAddress, postazione.ip, userType, k);
                            setStudents(prevStudents => [...prevStudents, myStudent]);
                            k++;
                            return [postazione.computerName];
                        } else {
                            const newRowSelection = { ...rowSelection };
                            delete newRowSelection[`${i}.${j}`];
                            setRowSelection(newRowSelection);
                            return [];
                        }
                    }
                }
                k++;
                return [];

            })
        );
        console.log("DEBUG selectedLabs: " + JSON.stringify(selectedLabs));

        // Rimuovi i duplicati e salva lo state
        labNamesArray = [...new Set(labNamesArray)];
        setPhysicalLabs(labNamesArray);
        console.log("DEBUG labNamesArray: " + JSON.stringify(labNamesArray));


    }, [rowSelection, allocationMode, labServicesVirtualmachines, setStudents, setPhysicalLabs, dataLib, defineUser]);




    useEffect(() => {
        var formBody = [];
        var payload = {
            key: chiave,
            value: searchLib
        }

        function fetchData() {
            setTimeout(async () => {
                setIsLoading(1);

                formBody.push("payload=" + JSON.stringify(payload));
                await fetch(process.env.REACT_APP_URL_LIBINVENTORY_GET, {
                    credentials: 'include',
                    method: "POST",
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
                    },
                    body: formBody
                })
                    .then((resp) => {
                        return resp.json()
                    })
                    .then((data) => {
                        setDataLib(data);
                    })
                    .catch((error) => {
                        console.log(error, "Errore!")
                    });

                setIsLoading(0);
            });

        }

        setDataLib([])
        setReloadData(false);

        fetchData();

    }, [searchLib, chiave, reloadData]);


    const onSubmit = (e) => {
        e.preventDefault();
        //var formBody = [];
        //var url;
    }


    const table = useMaterialReactTable({
        columns: columnsLib,
        data: dataLib || [],

        defaultDisplayColumn: { enableResizing: true },
        enableColumnResizing: true,
        enableRowActions: false,

        enableRowSelection: true,


        enableExpandAll: false, //hide expand all double arrow in column header
        enableExpanding: true,
        filterFromLeafRows: true, //apply filtering to all rows instead of just parent rows
        getSubRows: (row) => row.postazioni,
        initialState: {
            expanded: false,
            pagination: { pageIndex: 0, pageSize: 20 },
        },
        paginateExpandedRows: false,
        muiTableContainerProps: { sx: { maxHeight: '800px' } },
        onRowSelectionChange: setRowSelection,
        state: { rowSelection },

    });

    const savedPhysicalLab = () => {
        if (physicalLabs && physicalLabs.length > 0) {
            return (
                <Typography variant="h6">I laboratori fisici associati precedentemente salvati sono: {physicalLabs.join(', ')}</Typography>
            )
        }

    }


    if (!dataLib) {
        ret = null;
    } else {
        ret = (
            <React.Fragment>

                <h2 className="title-page">Selezionare i LIB (Laboratori fisici) per lo svolgimento dell'esame </h2>
                {savedPhysicalLab()}
                <form onSubmit={onSubmit}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography variant="h6">Ricerca LIB</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography variant="body2" color="textSecondary">Selezionare la chiave</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography variant="body2" color="textSecondary">Inserire il Valore</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl fullWidth>
                                <InputLabel id="select-chiave-label">Chiave</InputLabel>
                                <Select
                                    labelId="select-chiave-label"
                                    id="select-chiave"
                                    value={chiave}
                                    label={chiaveDes}
                                    onChange={(event) => {
                                        const selectedValue = event.target.value;
                                        switch (selectedValue) {
                                            case "nome":
                                                selectChiave("nome", "Nome LIB");
                                                break;
                                            case "edificio":
                                                selectChiave("edificio", "Edificio");
                                                break;
                                            case "subnet":
                                                selectChiave("subnet", "Subnet");
                                                break;
                                            case "ip":
                                                selectChiave("ip", "IP");
                                                break;
                                            default:
                                                break;
                                        }
                                    }}
                                >
                                    <MenuItem value="nome">Nome LIB</MenuItem>
                                    <MenuItem value="edificio">Edificio</MenuItem>
                                    <MenuItem value="subnet">Subnet</MenuItem>
                                    <MenuItem value="ip">IP</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={4}>
                            <TextField
                                fullWidth
                                required
                                type="text"
                                onChange={searchLibHandler}
                                value={searchLib}
                                label="Valore"
                                InputProps={{
                                    readOnly: !isKeySelected,
                                }}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <ClipLoader
                                size={25}
                                color={"#a71e3b"}
                                loading={isLoading}
                            />
                        </Grid>
                    </Grid>
                </form>
                <MaterialReactTable table={table} />


            </React.Fragment>
        );
    }



    return ret;

}

export default SelectLib