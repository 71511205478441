import React from 'react';
import { Alert, AlertTitle, Card, Button, Link } from '@mui/material';
import Can from "../Can";

function ReqLabConfirm(props) {
    const {
        apiAuthGroups,
        isSubmitble,
        isSubmitting,
        isSubmitted,
        statusRequest,
        buttonDescription,
        isAdmin,
    } = props;



    if (!isSubmitble) {
        return (
            <Button variant="contained" type="submit" disabled>
                {buttonDescription}
            </Button>
        );
    }

    if (isSubmitted) {
        let redirectUrl;
        if (isAdmin === undefined) {
            redirectUrl = "/";
        } else {
            redirectUrl = isAdmin ? "/labConsolle" : "/PubLab";
        }

        return statusRequest === "success" ? (
            <Alert severity="success">
                <AlertTitle>Richiesta acquisita con successo!</AlertTitle>
                <p>
                    Il Laboratorio Virtuale richiesto è in lavorazione. È possibile consultare lo stato di avanzamento
                    della richiesta da <Link href={redirectUrl}>Gestione Laboratori</Link>.
                </p>
            </Alert>
        ) : (
            <Alert severity="error">
                <AlertTitle>Si è verificato un errore!</AlertTitle>
                <p>Contattare l'assistenza.</p>
            </Alert>
        );
    }

    return (
        <Can
            role={apiAuthGroups}
            perform="labRequest-page:confirm"
            yes={() => (
                <Card sx={{ padding: 3, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Button
                        variant="contained"
                        type="submit"
                        disabled={isSubmitting}
                    >
                        {isSubmitting ? "Invio in corso..." : buttonDescription}
                    </Button>
                </Card>
            )}
            no={() => (
                <Card sx={{ padding: 3 }}>
                    <Alert severity="warning">
                        <AlertTitle>Funzionalità di richiesta Laboratorio Virtuale non abilitata</AlertTitle>
                    </Alert>
                </Card>
            )}
        />
    );


}

export default ReqLabConfirm;