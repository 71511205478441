import React, { useState, useEffect, useMemo, useRef, useCallback } from "react";
import { ProgressBar } from 'react-bootstrap';
import { Link45deg } from "react-bootstrap-icons";
import SwitchPubLab from "./SwitchPubLab";
import { Box, Grid, Popover, Button, Typography } from '@mui/material';
import {
    MaterialReactTable,
    useMaterialReactTable,
} from 'material-react-table';
import {
    useInfiniteQuery,
} from '@tanstack/react-query';
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state';




const labColumns = [
    {
        accessorKey: 'email',
        header: 'E-Mail',
        size: 200,
    },
    {
        accessorKey: 'idLab',
        header: 'Id Lab',
        size: 120,
    },
    {
        accessorKey: 'labName',
        header: 'Nome Lab',
        size: 180,
    },
    {
        accessorKey: "labType",
        header: "Tipo Lab",
        size: 140,
    },
    {
        accessorKey: "createdAt",
        header: "Data richiesta",
        size: 180,
    },
    {
        accessorKey: "status",
        header: "Stato richiesta",
        size: 180,
    },
    {
        accessorKey: "statusChart",
        header: "Avanzamento richiesta",
        size: 180,
        enableSorting: false,
    },
    {
        accessorKey: "registerUrlStatus",
        header: "Iscrizioni abilitate",
        size: 180,
        enableSorting: false,
    },
    {
        accessorKey: "nextActivity",
        header: "Prossima attività",
        size: 195,
    },
    {
        accessorKey: "physicalLabs",
        header: "Lab fisici",
        size: 120,
        Cell: ({ cell }) => {
            return Array.isArray(cell.row.original.physicalLabs) ? cell.row.original.physicalLabs.join(", ") : "";
        },
        enableSorting: false,
    },
];

const fetchSize = 25;

const PubLabInfiniteTable = ({ apiAuthResponse,
    setSelectedLabProps,
    setIsShowSelectedLab,
    setIsShowScheduleLab,
    setIsShowDropLab,
    setIsShowCloneLab,
    isAdmin }) => {
    const tableContainerRef = useRef(null); //we can get access to the underlying TableContainer element and react to its scroll events
    const rowVirtualizerInstanceRef = useRef(null); //we can get access to the underlying Virtualizer instance and call its scrollToIndex method

    const [columnFilters, setColumnFilters] = useState([]);
    const [globalFilter, setGlobalFilter] = useState();
    const [sorting, setSorting] = useState([{ "id": "idLab", "desc": true }]);




    const handleClickDetail = (e) => {
        setSelectedLabProps(e);
        setIsShowSelectedLab(true);
    }

    const handleClickSchedule = (e) => {
        setSelectedLabProps(e);
        setIsShowScheduleLab(true);
    }

    const handleClickDrop = (e) => {
        setSelectedLabProps(e);
        setIsShowDropLab(true);
    }

    const handleClickClone = (e) => {
        setSelectedLabProps(e);
        setIsShowCloneLab(true);
    }



    function convertDate(inputFormat) {
        function pad(s) { return (s < 10) ? '0' + s : s; }
        var d = new Date(inputFormat)
        return [pad(d.getDate()), pad(d.getMonth() + 1), d.getFullYear()].join('/')
    };


    const { data, fetchNextPage, isError, isFetching, isLoading } =
        useInfiniteQuery({
            queryKey: [
                'table-data',
                columnFilters, //refetch when columnFilters changes
                globalFilter, //refetch when globalFilter changes
                sorting, //refetch when sorting changes
            ],
            queryFn: async ({ pageParam }) => {
                const url = new URL(
                    '/auth/reqLab/getAllLabBatch',
                    process.env.REACT_APP_URL_GET_ALL_LAB_BATCH,
                );
                url.searchParams.set('start', `${pageParam * fetchSize}`);
                url.searchParams.set('size', `${fetchSize}`);
                url.searchParams.set('filters', JSON.stringify(columnFilters ?? []));
                url.searchParams.set('globalFilter', globalFilter ?? '');
                url.searchParams.set('sorting', JSON.stringify(sorting ?? [{ "id": "idLab", "desc": true }]));
                url.searchParams.set('isAdmin', isAdmin);

                return fetch(url.href,
                    {
                        method: 'GET',
                        credentials: 'include',
                    })
                    .then(response => response.json())
                    .then((data) => {
                        var linkVdi = "";

                        data.data.forEach(data => {
                            var progressCount = 0;
                            var progressVariant = "";
                            var progressLabel = "";

                            var linkDisabled = true;
                            if (data.status === "complete") {
                                linkDisabled = false;
                            }

                            linkVdi = (
                                <PopupState variant="popover" popupId="demo-popup-popover">
                                    {(popupState) => (
                                        <div>
                                            <Button variant="contained" {...bindTrigger(popupState)}
                                                disabled={linkDisabled}
                                            >
                                                Link accesso VM
                                            </Button>
                                            <Popover
                                                {...bindPopover(popupState)}
                                                anchorOrigin={{
                                                    vertical: 'bottom',
                                                    horizontal: 'center',
                                                }}
                                                transformOrigin={{
                                                    vertical: 'top',
                                                    horizontal: 'center',
                                                }}
                                            >
                                                <Typography sx={{ p: 2 }}>Indirizzo per accedere alle VM del laboratorio.
                                                    Per accedere alle VM: <br />
                                                    a) utilizzare un browser HTML5 (es. Chrome, Edge), inserendo questo URL nella barra degli indirizzi <br />
                                                    b) utilizzare il client VMWare Horizon, specificando questo URL come "Connection Server". <br />
                                                    Indirizzo:<br />
                                                    <strong>{data.baseImage.destination.url}</strong>
                                                    {"     "}
                                                    <a
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                        href=
                                                        {data.baseImage.destination.url}
                                                    ><Link45deg /></a>
                                                    <br /></Typography>
                                            </Popover>
                                        </div>
                                    )}
                                </PopupState>
                            );
                            switch (data.status) {
                                case "creatingMaster":
                                    progressCount = 20;
                                    progressLabel = progressCount + '%'
                                    break;
                                case "readyMaster":
                                    progressCount = 60;
                                    progressLabel = progressCount + '%'
                                    break;
                                case "creatingLab":
                                    progressCount = 80;
                                    progressLabel = progressCount + '%'
                                    break;
                                case "updatingLab":
                                    progressCount = 85;
                                    progressLabel = progressCount + '%'
                                    break;
                                case "refreshingLab":
                                    progressCount = 90;
                                    progressLabel = progressCount + '%'
                                    break;
                                case "isRefreshing":
                                    progressCount = 95;
                                    progressLabel = progressCount + '%'
                                    break;
                                case "complete":
                                    progressCount = 100;
                                    progressLabel = "Complete"
                                    break;
                                case "error":
                                    progressCount = 100;
                                    progressVariant = "danger";
                                    progressLabel = "Error"
                                    break;
                                case "dropped":
                                    progressCount = 100;
                                    progressVariant = "info";
                                    progressLabel = "Dropped"
                                    break;
                                case "deleted":
                                    progressCount = 100;
                                    progressVariant = "info";
                                    progressLabel = "Deleted"
                                    break;
                                case "deleting":
                                    progressCount = 100;
                                    progressVariant = "info";
                                    progressLabel = "Deleting"
                                    break;
                                default:

                            }

                            //data.status = data.status;
                            data.statusChart = (
                                <ProgressBar
                                    variant={progressVariant}
                                    now={progressCount}
                                    label={progressLabel}
                                    style={{ width: "100%" }}
                                />
                            )

                            data.linkVdi = linkVdi;

                            data.createdAt = convertDate(data.createdAt);

                            data.registerUrlStatus = <SwitchPubLab
                                idLab={data.idLab}
                                registerUrlStatus={data.registerUrlStatus}
                            />;

                            if (data.registerUrls) {
                                data.registerUrl = (
                                    <PopupState variant="popover" popupId="demo-popup-popover">
                                        {(popupState) => (
                                            <div>
                                                <Button variant="contained" {...bindTrigger(popupState)}
                                                    disabled={linkDisabled}
                                                >
                                                    Link iscrizioni
                                                </Button>
                                                <Popover
                                                    {...bindPopover(popupState)}
                                                    anchorOrigin={{
                                                        vertical: 'bottom',
                                                        horizontal: 'center',
                                                    }}
                                                    transformOrigin={{
                                                        vertical: 'top',
                                                        horizontal: 'center',
                                                    }}
                                                >
                                                    <Typography sx={{ p: 2 }}>
                                                        <strong>Indirizzo da fornire agli studenti per effettuare l'iscrizione al laboratorio virtuale:</strong>
                                                        <ul>
                                                            {data.registerUrls.map((elem, index) => (
                                                                <li key={index}>
                                                                    <strong>Docente:</strong> {elem.teacher.email}, <strong>URL:</strong> {process.env.REACT_APP_URL_REGISTER_LAB_FRONTEND + "/" + elem.registerUrl}
                                                                </li>
                                                            ))}
                                                        </ul>
                                                        Per il funzionamento dell'indirizzo le iscrizioni devono essere abilitate.
                                                    </Typography>
                                                </Popover>
                                            </div>
                                        )}
                                    </PopupState>
                                );
                            } else {
                                data.registerUrl = "";
                            }

                            let modificaDisabled = !["complete", "error"].includes(data.status);
                            data.labDetails = (
                                <Button
                                    size="sm"
                                    variant="contained"
                                    onClick={() => { handleClickDetail(data) }}
                                    disabled={modificaDisabled}
                                >
                                    Modifica
                                </Button>
                            );


                            let pianificazioneDisabled = !["complete"].includes(data.status);
                            data.labSchedule = (
                                <Button
                                    size="sm"
                                    variant="contained"
                                    onClick={() => { handleClickSchedule(data) }}
                                    disabled={pianificazioneDisabled}
                                >
                                    Pianificazione
                                </Button>
                            );

                            let cancellaDisabled = ["dropped", "deleted"].includes(data.status);
                            data.labDrop = (
                                <Button
                                    size="sm"
                                    variant="contained"
                                    onClick={() => { handleClickDrop(data) }}
                                    disabled={cancellaDisabled}
                                >
                                    Cancella
                                </Button>
                            );

                            let cloneDisabled = !["complete", "dropped", "deleted"].includes(data.status);
                            data.labClone = (
                                <Button
                                    size="sm"
                                    variant="contained"
                                    onClick={() => { handleClickClone(data) }}
                                    disabled={cloneDisabled}
                                >
                                    Clona
                                </Button>
                            );


                            // Assuming usageData.schedulerLab is an array of objects with a start property
                            const today = new Date();
                            today.setHours(0, 0, 0, 0); // Set to start of day

                            const futureActivities = data.usageData.schedulerLab.filter((activity) => {
                                const activityDate = new Date(activity.start);
                                activityDate.setHours(0, 0, 0, 0); // Set to start of day
                                return activityDate >= today;
                            });

                            futureActivities.sort((a, b) => new Date(a.start) - new Date(b.start));
                            var nextActivity = futureActivities.length > 0 ? futureActivities[0].start : null;
                            if (nextActivity) {
                                nextActivity = new Date(nextActivity).toLocaleString();
                            }
                            data.nextActivity = nextActivity;

                        });

                        return data;

                    })

                    .catch((error) => {
                        console.log(error, "Errore!")
                    });
            },
            initialPageParam: 0,
            getNextPageParam: (_lastGroup, groups) => groups.length,
            refetchOnWindowFocus: false,
        });

    const flatData = useMemo(
        () => data?.pages.flatMap((page) => page.data) ?? [],
        [data],
    );

    const totalDBRowCount = data?.pages?.[0]?.meta?.totalRowCount ?? 0;
    const totalFetched = flatData.length;

    const fetchMoreOnBottomReached = useCallback(
        (containerRefElement) => {
            if (containerRefElement) {
                const { scrollHeight, scrollTop, clientHeight } = containerRefElement;
                //once the user has scrolled within 400px of the bottom of the table, fetch more data if we can
                if (
                    scrollHeight - scrollTop - clientHeight < 400 &&
                    !isFetching &&
                    totalFetched < totalDBRowCount
                ) {
                    fetchNextPage();
                }
            }
        },
        [fetchNextPage, isFetching, totalFetched, totalDBRowCount],
    );

    useEffect(() => {
        //scroll to the top of the table when the sorting changes
        try {
            rowVirtualizerInstanceRef.current?.scrollToIndex?.(0);
        } catch (error) {
            console.error(error);
        }
    }, [sorting, columnFilters, globalFilter]);

    useEffect(() => {
        fetchMoreOnBottomReached(tableContainerRef.current);
    }, [fetchMoreOnBottomReached]);



    const table =
        useMaterialReactTable({

            columns: labColumns,
            data: flatData || [],
            enablePagination: false,
            enableRowNumbers: true,
            enableRowVirtualization: true,
            manualFiltering: true,
            manualSorting: true,
            muiTableContainerProps: {
                ref: tableContainerRef, //get access to the table container element
                sx: { maxHeight: '600px' }, //give the table a max height
                onScroll: (event) => fetchMoreOnBottomReached(event.target), //add an event listener to the table container element
            },
            muiToolbarAlertBannerProps: isError
                ? {
                    color: 'error',
                    children: 'Error loading data',
                }
                : undefined,
            onColumnFiltersChange: setColumnFilters,
            onGlobalFilterChange: setGlobalFilter,
            onSortingChange: setSorting,
            renderBottomToolbarCustomActions: () => (
                <Typography>
                    Fetched {totalFetched} of {totalDBRowCount} total rows.
                </Typography>
            ),
            state: {
                columnFilters,
                globalFilter,
                isLoading,
                showAlertBanner: isError,
                showProgressBars: isFetching,
                sorting,
            },
            rowVirtualizerInstanceRef, //get access to the virtualizer instance
            rowVirtualizerOptions: { overscan: 4 },



            enableExpandAll: false, //disable expand all button
            muiDetailPanelProps: () => ({
                sx: (theme) => ({
                    backgroundColor:
                        theme.palette.mode === 'dark'
                            ? 'rgba(255,210,244,0.1)'
                            : 'rgba(0,0,0,0.1)',
                }),
            }),
            muiExpandButtonProps: ({ row, table }) => ({
                onClick: () => table.setExpanded({ [row.id]: !row.getIsExpanded() }), //only 1 detail panel open at a time
                sx: {
                    transform: row.getIsExpanded() ? 'rotate(180deg)' : 'rotate(-90deg)',
                    transition: 'transform 0.2s',
                },
            }),
            renderDetailPanel: ({ row }) =>
                row.original.idLab ? (


                    <Box>
                        <Grid container spacing={2} direction="row">
                            <Grid item>
                                {row.original.linkVdi}
                            </Grid>
                            <Grid item>
                                {row.original.registerUrl}
                            </Grid>
                            <Grid item>
                                {row.original.labDetails}
                            </Grid>
                            <Grid item>
                                {row.original.labSchedule}
                            </Grid>
                            <Grid item>
                                {row.original.labDrop}
                            </Grid>
                            <Grid item>
                                {row.original.labClone}
                            </Grid>
                        </Grid>
                    </Box>


                ) : null,
        });

    return <MaterialReactTable table={table} />;

}

export default PubLabInfiniteTable;
