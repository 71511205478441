import React, { useEffect, useState } from 'react';
import { Calendar, momentLocalizer } from "react-big-calendar";
import { Form, Col, Row, Modal } from 'react-bootstrap';
import { Button } from '@mui/material';
import DatePicker, { registerLocale } from 'react-datepicker';
import it from "date-fns/locale/it";
import moment from 'moment';
import 'moment/locale/it';

import "react-big-calendar/lib/css/react-big-calendar.css";
import 'react-datepicker/dist/react-datepicker.css';


const localizer = momentLocalizer(moment);


function SchedulerLab(props) {
    const [showModal, setShowModal] = useState(false);
    const [dtStartEvent, setDtStartEvent] = useState();
    const [dtEndEvent, setDtEndEvent] = useState();
    const [scheduleType, setscheduleType] = useState("");
    const [titleEvent, settitleEvent] = useState("");
    const [localEvents, setLocalEvents] = useState([]);
    const [dtInitialStartEvent, setInitialDtStartEvent] = useState();
    const [dtInitialEndEvent, setInitialDtEndEvent] = useState();

    const {
        baseImage,
        labEvents,
        defaultView,
        email,
        isSubmitted,
        setIsSubmitble,
        setLabEvents,
        defaultDate
    } = props;

    var ret;

    registerLocale("it", it);

    useEffect(() => {
        var usageData;
        const evtArray = [];
        var mySchedule = [];

        usageData = baseImage.usageData;

        if (labEvents.length === 0) {
            mySchedule = usageData.schedulerLab;
            console.log("mySchedule usagedata: ", mySchedule);
        } else {
            mySchedule = labEvents;
            console.log("mySchedule labEvents: ", mySchedule);
        }

        mySchedule.forEach(element => {
            let myTitle = "";
            let myColor = "";

            if (defaultView === "day") {
                myTitle = 'idLab: ' + element.idLab + ', ' +
                    ' labName: ' + element.labName + ', ' +
                    ' destId: ' + element.destinationId + ', ' +
                    ' teacher: ' + element.teacher.email
            } else {
                myTitle = element.teacher.email;
            }

            if (element.scheduleType === "easy") {
                myColor = "red";
            } else {
                myColor = "#3174ad";
            }

            var a = {
                title: myTitle,
                start: moment(element.start).toDate(),
                end: moment(element.end).toDate(),
                teacher: element.teacher.email,
                scheduleType: element.scheduleType,
                color: myColor
            }

            evtArray.push(a);
        });

        setLocalEvents(evtArray);

    }, [baseImage, email, defaultView, labEvents]);


    // Gestione selezione evento
    const handleSelect = ({ title, start, end, color }) => {
        if (!isSubmitted) {
            setDtStartEvent(start);
            setDtEndEvent(end);

            setInitialDtStartEvent(start);
            setInitialDtEndEvent(end);

            color === "red" ? setscheduleType("easy") : setscheduleType("web");
            settitleEvent(title);

            setShowModal(true);
        }
    };

    // Gestione chiusura modale
    const handleCloseModal = () => {
        setShowModal(false);
    }


    // Gestione rimozione di un evento
    const handleDeleteModal = () => {
        const localLabEvents = localEvents;
        const dbEvents = [];

        // Elimino l'elemento selezionato
        const newLocalLabEvents = localLabEvents.filter(function (value, index, arr) {
            return !(value.start === dtStartEvent &&
                value.end === dtEndEvent &&
                value.scheduleType === scheduleType);
        });

        // Popolo l'array da salvare su db
        newLocalLabEvents.forEach((localEvent, index) => {
            var dbEvent = {
                teacher: {
                    email: localEvent.teacher,
                },
                start: localEvent.start,
                end: localEvent.end,
                scheduleType: localEvent.scheduleType,
            };

            dbEvents.push(dbEvent);
        });

        setLabEvents(dbEvents)
        setLocalEvents(newLocalLabEvents);

        setIsSubmitble(true);
        setShowModal(false);
    };


    // Gestione salvataggio di un evento
    const handleSaveModal = () => {
        const dbEvents = [];

        // Elimino l'elemento selezionato
        const newLocalEvents = localEvents.filter(function (value, index, arr) {
            return !(value.start === dtInitialStartEvent &&
                value.end === dtInitialEndEvent &&
                value.scheduleType === scheduleType);
        });

        // Definisco l'evento corrente da salvere
        var event = {
            title: titleEvent,
            start: dtStartEvent,
            end: dtEndEvent,
            teacher: email,
            scheduleType: scheduleType,
        }

        // Aggiungo l'evento all'array locale
        newLocalEvents.push(event);
        setLocalEvents(newLocalEvents)

        newLocalEvents.forEach((localEvent, index) => {
            var dbEvent = {
                teacher: {
                    email: localEvent.teacher,
                },
                start: localEvent.start,
                end: localEvent.end,
                scheduleType: localEvent.scheduleType,
            };

            dbEvents.push(dbEvent);
        });

        setLabEvents(dbEvents);
        setIsSubmitble(true);

        setShowModal(false);
    };



    const modalEvent = () => {
        if (!showModal) return;

        var retModal;

        retModal = (
            <Modal
                className={'modal-dialog-centered'}
                show={showModal}
                onHide={handleCloseModal}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Prenotazione Virtual Lab</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>

                        <Form.Group controlId="ModalForm.ScheduleEvent1">
                            <Form.Label>Docente</Form.Label>
                            <Form.Control
                                type="email"
                                placeholder={email}
                                readOnly
                            />
                            <Form.Label>Laboratorio</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder={baseImage.imageDes}
                                readOnly
                            />
                            <Form.Label>Definizione evento</Form.Label>
                            <Row>
                                <Col>
                                    <Form.Text className="text-muted">Inizio</Form.Text>
                                </Col>
                                <Col>
                                    <Form.Text className="text-muted">Fine</Form.Text>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <DatePicker
                                        selected={dtStartEvent}
                                        onChange={date => setDtStartEvent(date)}
                                        showTimeSelect
                                        showTimeSelectOnly
                                        timeIntervals={15}
                                        timeCaption="Time"
                                        dateFormat="dd/mm/yyyy HH:mm"
                                        locale="it"
                                        inline
                                    />
                                </Col>
                                <Col>
                                    <DatePicker
                                        selected={dtEndEvent}
                                        onChange={date => setDtEndEvent(date)}
                                        showTimeSelect
                                        showTimeSelectOnly
                                        timeIntervals={15}
                                        timeCaption="Time"
                                        dateFormat="dd/mm/yyyy HH:mm"
                                        locale="it"
                                        inline
                                    />
                                </Col>
                            </Row>
                        </Form.Group>
                    </Form>

                </Modal.Body>
                <Modal.Footer>

                    <Button variant="secondary" onClick={handleDeleteModal}>
                        Elimina
                    </Button>
                    <Button variant="contained" onClick={handleSaveModal}>
                        Inserisci prenotazione
                    </Button>
                </Modal.Footer>
            </Modal>
        );

        return retModal;

    }


    ret = (
        <React.Fragment>
            <h3>Pianificazione</h3>
            {modalEvent()}
            <Calendar
                selectable={!isSubmitted}
                defaultView={defaultView}
                defaultDate={defaultDate}
                scrollToTime={new Date()}
                localizer={localizer}
                events={localEvents}
                startAccessor="start"
                endAccessor="end"
                style={{ height: 850 }}
                onSelectSlot={handleSelect}
                onSelectEvent={handleSelect}
                messages={{
                    next: "Prossimo",
                    previous: "Precedente",
                    today: "Oggi",
                    month: "Mese",
                    week: "Settimana",
                    day: "Giorno"
                }}
                eventPropGetter={event => {
                    return { style: { backgroundColor: event.color } };
                }}
            />
        </React.Fragment>
    );

    return ret;
}

export default SchedulerLab;