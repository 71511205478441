import React, { useState, useEffect, useRef, useCallback } from 'react';
import { TextField, InputLabel, Typography, Alert, InputAdornment, Card } from '@mui/material';


function LabName(props) {
    const [localLabName, setLocalLabName] = useState("");
    const [showDupAlert, setShowDupAlert] = useState(false);
    const [debouncedLabName, setDebouncedLabName] = useState("");
    const targetDupAlert = useRef(null);
    const {
        labNameSelected,
        setIsLabnameChecked,
        isLabnameChecked,
        adSel,
        onCompletion,
    } = props;

    const isAdSelEmpty = Object.keys(adSel || {}).length === 0;

    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedLabName(localLabName);
        }, 500); // 500ms di debounce

        return () => {
            clearTimeout(handler);
        };
    }, [localLabName]);

    // Memorizza funzioni per evitare re-render non necessari
    const memoizedLabNameSelected = useCallback(
        (labName) => labNameSelected(labName),
        [labNameSelected]
    );

    const memoizedOnCompletion = useCallback(
        (status) => onCompletion(status),
        [onCompletion]
    );

    const labNameHandler = (event) => {
        const inputValue = event.target.value;
        let sanitizedValue = inputValue.replace(/[^a-zA-Z0-9-]/g, ""); // Permette solo lettere, numeri, "-"
        sanitizedValue = sanitizedValue.substring(0, 100); // Limita la lunghezza a 100 caratteri


        if (inputValue !== sanitizedValue) {
            setIsLabnameChecked(false); // Segnala errore se ci sono caratteri vietati
        } else {
            setIsLabnameChecked(true);
        }

        setLocalLabName(sanitizedValue);
    }

    useEffect(() => {
        if (!debouncedLabName) {
            memoizedOnCompletion(false);
            return;
        }

        let globalLabName;
        if (adSel && Object.keys(adSel).length > 0) {
            globalLabName = `${adSel.anaAfCod}-${adSel.anaModCod}-${debouncedLabName}`;
        } else {
            globalLabName = `other-${debouncedLabName}`;
        }

        memoizedLabNameSelected(globalLabName);

        fetch(`${process.env.REACT_APP_URL_CHECKNAME_LAB}?labName=${globalLabName}`, {
            credentials: 'include',
            method: "GET"
        })
            .then((resp) => resp.json())
            .then((data) => {
                if (data.isLabNameDup) {
                    setShowDupAlert(true);
                    memoizedOnCompletion(false);
                } else {
                    setShowDupAlert(false);
                    memoizedOnCompletion(debouncedLabName.length > 0 && isLabnameChecked);
                }
            })
            .catch((error) => {
                console.log(error, "Errore!");
            });
    }, [debouncedLabName, adSel, isLabnameChecked, memoizedLabNameSelected, memoizedOnCompletion]);


    return (
        <Card sx={{ padding: 3 }}>
            <InputLabel>Nome del Laboratorio Virtuale</InputLabel>
            <Typography variant="body2" color="textSecondary">
                <b>Dato obbligatorio</b>
            </Typography>
            <TextField
                fullWidth
                required
                inputRef={targetDupAlert}
                type="text"
                onChange={labNameHandler}
                placeholder="Inserire il nome del Laboratorio"
                variant="outlined"
                margin="normal"
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            {!isAdSelEmpty ?
                                `${adSel?.anaAfCod}-${adSel?.anaModCod}-` :
                                'other-'}
                        </InputAdornment>
                    ),
                }}
            />


            {showDupAlert &&
                <Alert variant="danger">
                    Nome Laboratorio Virtuale già usato! Inserire un nome diverso.
                </Alert>
            }
            {!isLabnameChecked && !showDupAlert &&
                <Alert variant="danger">
                    Attenzione! Il nome inserito contiene caratteri non consentiti. Inserire un nome diverso.
                </Alert>
            }
        </Card >


    );



}

export default LabName;