import React, { useState, useEffect, useMemo } from 'react';
import { TextField, Button, Typography, Box } from '@mui/material';
import {
    MaterialReactTable,
    useMaterialReactTable,
} from 'material-react-table';

const StudentList = (props) => {
    const [rowSelection, setRowSelection] = useState({});
    const [tabTeachers, setTabTeachers] = useState([]);
    const [tabStudents, setTabStudents] = useState([]);
    const [selectedStudents, setSelectedStudents] = useState([]);
    const [emails, setEmails] = useState('');
    const [teacherEmails, setTeacherEmails] = useState('');

    const {
        isAdmin,
        apiAuthResponse,
        students,
        setStudents,
        teachers,
        setTeachers,
    } = props;


    const labelStudentBooked = isAdmin ? "Studenti iscritti" : "Propri studenti iscritti";


    const teacherColumns = useMemo(
        () => [
            {
                accessorKey: 'teacher',
                header: 'Docente',
                size: 120,
            }
        ],
        [],
    );

    const studentsColumns = useMemo(
        () => [
            {
                accessorKey: 'teacher.email',
                header: 'Docente',
                size: 120,
            },
            {
                accessorKey: 'partition',
                header: 'Turno',
                size: 90,
            },
            {
                accessorKey: 'email',
                header: 'Studente',
                size: 150,
            },
            {
                accessorKey: "registrationDate",
                header: "Data iscrizione",
                size: 120,
                Cell: ({ cell }) => new Date(cell.getValue()).toLocaleString('it-IT'),
            },
        ],
        [],
    );

    const handleImportStudents = () => {
        const emailArray = emails.split(',').map(email => email.trim()).filter(email => email);
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const newStudents = emailArray
            .filter(email => emailRegex.test(email) && !tabStudents.some(student => student.email === email))
            .map(email => (
                {
                    teacher: {
                        email: apiAuthResponse,
                        partition: 0,
                    },
                    email,
                    partition: 0,
                    registrationDate: new Date().toISOString(),
                    userType: 'standard',
                }
            ));
        setTabStudents(prevStudents => [...prevStudents, ...newStudents]);
        setEmails('');
    };

    const handleImportTeachers = () => {
        const emailArray = teacherEmails.split(',').map(email => email.trim()).filter(email => email);
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const newTeachers = emailArray
            .filter(email => emailRegex.test(email) && !tabTeachers.some(teacher => teacher.email === email))
            .map(email => (
                {
                    teacher: email,
                }
            ));
        setTabTeachers(prevTeachers => [...prevTeachers, ...newTeachers]);
        setTeacherEmails('');
    };

    const teachersTable = useMaterialReactTable({
        columns: teacherColumns,
        data: tabTeachers || [], //data must be memoized or stable (useState, useMemo, defined outside of this component, etc.)
    });

    const studentsTable =
        useMaterialReactTable({
            columns: studentsColumns,
            data: tabStudents || [],
            enableBottomToolbar: false,
            enableGlobalFilterModes: true,
            enablePagination: false,
            enableColumnPinning: true,
            enableRowNumbers: false,
            enableRowVirtualization: true,
            enableRowSelection: true,
            enableSelectAll: true,
            onRowSelectionChange: setRowSelection,
            state: { rowSelection }
        });



    // popola gli oggetti usati dalle tabelle con i dati degli studenti e dei docenti
    // per la visutalizzazione delle liste
    useEffect(() => {
        const tabStudent = [];
        const tabTeacher = [];

        if (students) {
            students.map((value) => {
                if (value.teacher.email === apiAuthResponse || isAdmin) {
                    //console.log("DEBUG: " + value.teacher.email + " - " + apiAuthResponse);
                    console.log("DEBUG: " + JSON.stringify(value));
                    tabStudent.push(
                        {
                            teacher: value.teacher,
                            partition: value.teacher.partition,
                            email: value.email,
                            registrationDate: value.registrationDate,
                        }
                    )
                }
                return tabStudent;
            });
            setTabStudents(tabStudent);
        }

        if (teachers) {
            teachers.map((value) => {
                tabTeacher.push(
                    {
                        teacher: value.email,
                    }
                )
                return tabTeacher;
            });
            setTabTeachers(tabTeacher);
        }




    }, [students, teachers, apiAuthResponse, isAdmin, setTabStudents]);


    // gestisce la selezione degli studenti selezionati
    // serve per popolare l'array degli studenti selezionati che vanno esclusi dalla lista
    useEffect(() => {
        if (!tabStudents || tabStudents.length === 0) {
            setSelectedStudents([]);
            return;
        }

        const selectedEmails = Object.keys(rowSelection)
            .filter(index => rowSelection[index] && tabStudents[index]) // Ensure the index is valid
            .map(index => tabStudents[index].email); // Map each index to the email of the corresponding student

        setSelectedStudents(selectedEmails);
    }, [rowSelection, tabStudents, setSelectedStudents]);


    // aggiorna la lista degli studenti, eliminando quelli selezionati
    useEffect(() => {
        // Rimuovi gli studenti selezionati
        const updatedTabStudents = tabStudents.filter(student =>
            !selectedStudents.includes(student.email)
        );

        setStudents(updatedTabStudents);

    }, [selectedStudents, students, setStudents, tabStudents]);


    useEffect(() => {
        // Trasforma tabTeachers in un array locale con il formato richiesto
        const formattedTeachers = tabTeachers.map(teacher => (
            {
                email: teacher.teacher,
                partitions: [0]
            }));

        setTeachers(formattedTeachers);
    }, [teachers, tabTeachers, setTeachers]);


    return (
        <React.Fragment>
            <Box mb={2}>
                <Typography variant="h6">Docenti gestori del laboratorio</Typography>
                <MaterialReactTable table={teachersTable} />
            </Box>

            <Box mb={2}>
                <Typography variant="h6">Importa docenti (email separate da virgole)</Typography>
                <TextField
                    value={teacherEmails}
                    onChange={(e) => setTeacherEmails(e.target.value)}
                    fullWidth
                    multiline
                    rows={4}
                    variant="outlined"
                />
                <Button onClick={handleImportTeachers} variant="contained" color="primary" style={{ marginTop: '10px' }}>
                    Importa Docenti
                </Button>
            </Box>

            <Box mb={2}>
                <Typography variant="h6">{labelStudentBooked}</Typography>
                <Typography variant="body1" color="textSecondary" sx={{ marginBottom: 2 }}>
                    Selezionare gli studenti che si vuole rimuovere.
                </Typography>
                <MaterialReactTable table={studentsTable} />
            </Box>

            <Box mb={2}>
                <Typography variant="h6">Importa studenti (email separate da virgole)</Typography>
                <TextField
                    value={emails}
                    onChange={(e) => setEmails(e.target.value)}
                    fullWidth
                    multiline
                    rows={4}
                    variant="outlined"
                />
                <Button onClick={handleImportStudents} variant="contained" color="primary" style={{ marginTop: '10px' }}>
                    Importa Studenti
                </Button>
            </Box>

        </React.Fragment>
    );
};

export default StudentList;